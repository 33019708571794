import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components/common/typography';
import { Container, BlockQuote, IconContainer, Footer, Cite } from './styled';
// import * as colours from 'lib/theme/colours';
import QuotesRightIcon from 'components/common/icons/quotes-right';

const Index = ({ text, author }) => (
    <Container>
        <BlockQuote>
            <IconContainer>
                <QuotesRightIcon fill="#ffffff" height="60px" width="60px" />
            </IconContainer>
            <Heading as="p" appearance="paragon" light>{text}</Heading>
            <Footer>
                <Cite>{author}</Cite>
            </Footer>
        </BlockQuote>
    </Container>
);

Index.propTypes = {
    text: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired
};

export default Index;
