import styled from 'styled-components';
import { minWidth } from 'lib/breakpoints';
import { rem } from 'lib/units';

export const Container = styled.div`
    position: relative;
`;

export const Info = styled.div`
    padding: ${rem(12)};
    position: absolute;
    left: 0;
    bottom: ${rem(30)};
    text-align: left;
    @media (min-width: ${minWidth('lg')}) {
        left: ${rem(30)};
        width: 50%;
    }
`;

export const HeadingContainer = styled.div`
    margin-bottom: ${rem(12)};
`;

export const Scrim = styled.div`
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
`;

export const ContactContainer = styled.div`
    display: none;
    @media (min-width: ${minWidth('lg')}) {
        display: block;
        position: absolute;
        width: 300px;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);
    }
`;
