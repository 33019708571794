import React from 'react';
import ReactPlayer from 'react-player/lazy'
import { Container, Intro, VideoContainer, Heading } from './styled';
import { Text } from 'components/common/typography';

export default () => {
    return (
        <Container>
            <Intro>
                <Heading as="h2" appearance="paragon">Introduction Video</Heading>
                <Text>Find out more about our products and services in our latest video.</Text>
            </Intro>
            <VideoContainer>
                <ReactPlayer
                    className='react-player'
                    controls={true}
                    width='100%'
                    height='100%'
                    url='https://www.youtube.com/watch?v=8HqiUhaNWs8&feature=emb_logo' 
                />
            </VideoContainer>
        </Container>
    );
};