import styled from 'styled-components';
import { minWidth } from 'src/lib/breakpoints';
import { rem } from 'src/lib/units';

export const Grid = styled.ul`
    display: grid;
    grid-gap: ${rem(24)};
    grid-template-columns: 1fr;

    @media (min-width: ${minWidth('md')}) {
        grid-template-columns: repeat(2, 1fr);
        > :nth-child(3) {
            grid-column: 1 / 3;
        }
    } 
`;

export const Item = styled.li`
    
`;