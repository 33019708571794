import styled from 'styled-components';
import Link from 'gatsby-link';
// import * as colours from 'lib/theme/colours';


export const Lozenge = styled(Link)`
    display: inline-block;
    font-family: 'roboto-regular';
    color: #212121;
    background: white;
    /* box-shadow: inset 0 1px 0 0 #0ec5e3, inset -1px 0 0 0 #0ec5e3, inset 0 -1px 0 0 #0ec5e3, inset 1px 0 0 0 #0ec5e3; */
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 50px;
`;