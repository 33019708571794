import styled from 'styled-components';
import { minWidth } from 'lib/breakpoints';
import { rem } from 'lib/units';

export const Image = styled.img`
    height: auto;
    width: 100%;
`;

export const ImageContainer = styled.div`
    
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (min-width: ${minWidth('sm')}) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: ${minWidth('lg')}) {
        grid-template-columns: repeat(5, 1fr);
        margin: 0 ${rem(100)};
    }
`;

export const GridItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 112px;
    margin: 0 auto;
    transition: opacity 0.8s cubic-bezier(0.5, 0, 0, 1) 0s, transform 0.8s cubic-bezier(0.5, 0, 0, 1) 0s;
    transition-delay: ${({ delay }) => delay}, ${({ delay }) => delay};
    transition-property: transform, opacity;
    ${({ isVisible }) =>  isVisible ? `
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        opacity: 1;
    ` : `
        opacity: 0;
        transform: matrix3d(0.98, 0, 0, 0, 0, 0.98, 0, 0, 0, 0, 1, 0, 0, 15, 0, 1);
    `};
    padding: 12px;
    @media (min-width: ${minWidth('lg')}) {
        padding: 0;
    }
    
`;