import styled from 'styled-components';
import { rem } from 'src/lib/units';
import * as colours from 'lib/theme/colours';

export const Container = styled.section`
    
`;

export const IconContainer = styled.div`
    color: ${colours.BRAND_PRIMARY};
    margin-bottom: ${rem(24)};
`;

export const BlockQuote = styled.blockquote`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 664px;
    text-align: center;
`;

export const Footer = styled.footer``;

export const Cite = styled.cite`
    color: #707070;
    font-family: roboto-regular;
    font-size: 24px;
`;
