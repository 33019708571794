import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, Item } from './styled';
import CategoryCard from 'components/pages/index/category-card';
import Img from 'gatsby-image';
import { breakpoints } from 'lib/breakpoints';

export default () => {
    const data = useStaticQuery(query);
    const { seatingImage, breakoutImage, desksImageDesktop, desksImageMobile } = data;

    const sources = [
        desksImageMobile.childImageSharp.fluid,
        {
            ...desksImageDesktop.childImageSharp.fluid,
            media: `(min-width: ${breakpoints.md}px)`,
        }
    ];
    
    return (
        <Grid>
            <Item>
                <CategoryCard 
                    title="Seating" 
                    path="/categories/seating"
                    media={<Img fluid={seatingImage.childImageSharp.fluid} alt="Seating" />}
                />
            </Item>
            <Item>
                <CategoryCard 
                    title="Breakout / Co-Working" 
                    path="/categories/breakout-co-working"
                    media={<Img fluid={breakoutImage.childImageSharp.fluid} alt="Breakout / Co-Working" />}
                />
            </Item>
            <Item>
                <CategoryCard 
                    title="Desks" 
                    path="/categories/desks"
                    media={<Img fluid={sources} alt="Desks" />}
                />
            </Item>
        </Grid>
    );
};

const query = graphql`
query {
    seatingImage: file(relativePath: { eq: "content/home/categories/seating.jpg" }) {
        childImageSharp {
            fluid(maxHeight: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    breakoutImage: file(relativePath: { eq: "content/home/categories/breakout-co-working.jpg" }) {
        childImageSharp {
            fluid(maxHeight: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    desksImageDesktop: file(relativePath: { eq: "content/home/categories/desks.jpg" }) {
        childImageSharp {
            fluid(maxHeight: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    desksImageMobile: file(relativePath: { eq: "content/home/categories/desks_mobile.jpg" }) {
        childImageSharp {
            fluid(maxHeight: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
}
`;