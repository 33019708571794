import styled from 'styled-components';
import { rem } from 'lib/units';
// import * as colours from 'lib/theme/colours';

export const Container = styled.div`
    padding: ${rem(48)} 0;
    ${getBackground}
 
`;

Container.defaultValues = {
    appearance: 'default'
};

function getBackground({ appearance }) {
    if(appearance === 'blue') {
        return `background: rgba(14, 197, 226, 0.2);`
    } else {
        return `background: #111111;`
    }
}