import styled from 'styled-components';
import { rem } from 'src/lib/units';
import { minWidth, maxWidth } from 'src/lib/breakpoints';

export const Container = styled.section`
    margin: 0 auto ${rem(24)} auto;
    @media (min-width: ${minWidth('lg')}) {
        padding: ${rem(30)} ${rem(140)} ${rem(55)};
        text-align: center;
        margin: 0 auto;
    }
`;

export const Header = styled.header`
    margin-bottom: ${rem(30)};
    @media (min-width: ${minWidth('lg')}) {
        padding: 0 ${rem(100)};
    }
`;

export const Actions = styled.div`
    padding-top: ${rem(24)};
    @media (max-width: ${maxWidth('lg')}) {
        text-align: center;
    }
`;

export const Grid = styled.div`
    @media (min-width: ${minWidth('lg')}) {
        display: flex;
        p {
            padding: 0 12px;
        }
    }
`;