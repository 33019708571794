import React from 'react';
import { Container } from './styled';
import { Heading } from 'components/common/typography';
import { LinkAction } from 'components/common/link';

export default () => (
    <Container>
        <Heading as="h2" appearance="trafalgar">Ready to Talk?</Heading>
        <LinkAction to="/contact" appearance="primary" size="large">Contact us</LinkAction>
    </Container>
);