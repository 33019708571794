import styled from 'styled-components';
import { rem } from 'lib/units';
import { minWidth } from 'lib/breakpoints';
import { Heading as BaseHeading } from 'components/common/typography';

export const Container = styled.div`
    @media (min-width: ${minWidth('md')}) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: ${rem(24)};
    }
`;

export const Intro = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 2;
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
`;

export const Heading = styled(BaseHeading)`
    margin-top: 0;
`;