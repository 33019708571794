import styled from 'styled-components';
import { makeContainer } from 'lib/mixins';
import { rem } from 'lib/units';

export const Container = styled.div`
    ${makeContainer()};
`;

export const PrimaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    & > :nth-child(1) {
        order: 2;
    }
`;

export const Section = styled.div`
    margin-bottom: ${rem(48)};
`;



