import React from 'react';
import Layout from 'components/common/layout';
import { Heading } from 'components/common/typography';
import Story from 'components/pages/index/story';
// import Services from 'components/pages/index/services';
import Categories from 'components/pages/index/categories';
import { Container, PrimaryContainer, Section } from 'components/pages/index/template';
import HeroBanner from 'components/pages/index/hero-banner';
import Testimonial from '../components/pages/index/testimonial';
import Contact from 'components/pages/index/contact';
import Video from 'components/pages/index/video';
import Suppliers from 'components/pages/index/suppliers';
import SEO from 'components/common/seo';
import Slab from 'components/common/slab';

export default () => (
	<Layout>
		<SEO />
		<Container>
			<PrimaryContainer>
				<Story />
				<HeroBanner />
			</PrimaryContainer>
			<Section>
				<Categories />
			</Section>
		</Container>
		<Section>
			<Slab appearance="blue">
				<Container>
					<Video />
				</Container>
			</Slab>
		</Section>
		<Container>
			<Section>
				<Heading as="h2" appearance="paragon" center>Some of our Supplier Partners</Heading>
				<Suppliers />
			</Section>
		</Container>
		<Slab>
			<Container>
				<Testimonial
					text="I would thoroughly recommend Activ Business Solutions for their quality services."
					author="Facilities Manager, Quantuma"
				/>
			</Container>
		</Slab>
		<Contact />
		
	</Layout>
);