import React from 'react';
import { Container, Link, ImageContainer, MetaContainer, Title, ArrowContainer } from './styled';
import { Heading } from 'components/common/typography';
import ArrowRight from 'components/common/icons/arrow-right';

const Feature = ({ title, path, media }) => {  
    return (
        <Container>
            <Link to={path}>
                <ImageContainer>
                    {media}
                </ImageContainer>
                <MetaContainer>
                    <Title>
                        <Heading as="h2" appearance="paragon" light>{title}</Heading>
                    </Title>
                    <ArrowContainer>
                        <ArrowRight />
                    </ArrowContainer>
                </MetaContainer>
            </Link>
        </Container>
    );
};

export default Feature;