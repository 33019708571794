import React from 'react';
import Icon from './components/icon';
import ArrowRightIcon from './svg/arrow-right.svg';

const Index = () => (
    <Icon>
        <ArrowRightIcon />
    </Icon>
);

export default Index;