import React from 'react';
import { Container, IconContainer, Header, ContactHeading } from './styled';
import { Text } from 'components/common/typography';
import { LinkUnderline } from 'components/common/link';
import BubblesIcon from 'components/common/icons/bubbles';
import { generateTelNo, generateEmailString } from 'utils/helpers';

const email = generateEmailString();
const tel = generateTelNo();

export default () => (
    <Container>
        <Header>
            <IconContainer>
                <BubblesIcon />
            </IconContainer>
            <ContactHeading>Let's Talk!</ContactHeading>
        </Header>
        <Text appearance="longPrimer" light>Tell us about your project, get a quick estimate, ask a question or find out more about Activ.</Text>
        <Text light>
            Call us on<br /><LinkUnderline as="a" href={`tel:${tel}`} appearance="meta">{tel}</LinkUnderline>
        </Text>
        <Text light>
            Email us on<br /> <LinkUnderline as="a" href={`mailto:${email}`} appearance="meta">{email}</LinkUnderline>
        </Text>
    </Container>
);