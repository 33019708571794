import styled from 'styled-components';
import { maxWidth } from 'lib/breakpoints';
import { Heading } from 'components/common/typography';
import { rem } from 'lib/units';
import * as colours from 'lib/theme/colours';

export const Container = styled.div`
    background: ${colours.HIGHLIGHT};
    border-radius: 4px;
    padding: ${rem(30)};
    width: 100%;
    @media (max-width: ${maxWidth('lg')}) {
        margin-top: ${rem(12)};
    }
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

export const ContactHeading = styled(Heading).attrs({ appearance: 'paragon', light: true })`
    margin: 0;
`;

export const IconContainer = styled.div`
    color: white;
    margin-right: 12px;
`;