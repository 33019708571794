import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, ContactContainer, Scrim, Info, HeadingContainer } from './styled';
import ContactBox from 'components/common/contact-box';
import Img from "gatsby-image";
import { Lozenge } from 'components/common/buttons';
import { Heading, Text } from 'components/common/typography';

const HeroBanner = () => {
    const data = useStaticQuery(query);
    const {
        desktopImage,
        tabletImage,
        mobileImage,
    } = data;

    const sources = [
        mobileImage.childImageSharp.fluid,
        {
            ...desktopImage.childImageSharp.fluid,
            media: `(min-width: 841px)`,
        }, {
            ...tabletImage.childImageSharp.fluid,
            media: `(min-width: 641px)`,
        },
    ];

    return (
        <Container>
            <Img fluid={sources} />
            <Scrim />
            <Info>
                <HeadingContainer>
                    <Heading as="h2" upper light>Have an Open Plan Office?</Heading>
                    <Text appearance="pica" light>Learn more about breakout and co-working products and services</Text>
                </HeadingContainer>
                <Lozenge to="/categories/breakout-co-working">Visit Breakout / Co-working</Lozenge>
            </Info>
            <ContactContainer>
                <ContactBox />
            </ContactContainer>
        </Container>
    );
};

const query = graphql`
    query {
        desktopImage: file(relativePath: { eq: "content/home/hero-banner/openplan_2448x1200.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        tabletImage: file(relativePath: { eq: "content/home/hero-banner/openplan_1000x742.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        mobileImage: file(relativePath: { eq: "content/home/hero-banner/openplan_748x1000.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 374) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

export default HeroBanner;