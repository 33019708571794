import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { rem } from 'lib/units';
import { minWidth } from 'src/lib/breakpoints';

// It's in hero resuse
export const Scrim = styled.div`
    &:after {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
    }
`;

export const Container = styled.article`
    position: relative;
`;

export const Link = styled(GatsbyLink)`
    display: block;
`;

export const ImageContainer = styled(Scrim)``;

export const MetaContainer = styled.div`
    align-items: center;
    display: flex;
    position: absolute;
    bottom: ${rem(12)};
    left: ${rem(12)};
    @media (min-width: ${minWidth('lg')}) {
        bottom: ${rem(30)};
        left: ${rem(30)};
    }
`;

export const ArrowContainer = styled.span`
    color: #0ec5e3;
    margin-left: ${rem(12)};
    transform: translateY(20px);
    opacity: 0;
    transition: opacity .95s cubic-bezier(.2,1,.2,1), transform .95s cubic-bezier(.2,1,.2,1);
    ${Container}:hover & {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const Title = styled.div`
    align-items: center;
    color: #ffffff;
    display: flex;
    margin-bottom: ${rem(6)};
    * {
        margin: 0;
    }
`;
