import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled';

const Slab = ({ children, ...rest }) => <Container {...rest}>{children}</Container>;

Slab.propTypes = {
    appearance: PropTypes.oneOf(['default', 'blue']),
};

export default Slab;