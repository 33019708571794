import React from 'react';
import { Container, Header, Actions, Grid } from './styled';
import { Heading, Text } from 'components/common/typography';
import { LinkAction } from 'components/common/link';

const Story = () => (
    <Container>
        <Header>
            <Heading as="h1" appearance="paragon">Office Furniture and Office Interiors in Buckinghamshire, London and the South East</Heading>
        </Header>
        <Grid>
            <Text>As an industry leading supplier of office furniture, office design, office interiors and installations throughout Buckinghamshire, London and the South East, Activ Business Solutions supplies access to all your office furniture needs. With our range of manufacturer partners and our vast experience you can be comfortable your project is in safe hands, focused on delivering within budget and your defined timescale.</Text>
            <Text>We supply and deliver throughout the UK and within Europe so no matter the requirement of the project we can travel and deliver to provide your business with the perfect office furniture and interiors solution for you. With products built to last and service to deliver your solution, Activ Business Solutions can provide you with comfort your Buckinghamshire, London, South East of beyond office furniture project will be successfully delivered to the highest level.</Text>
        </Grid>
        <Actions>
            <LinkAction to="/about" size="large" appearance="primary">About Activ</LinkAction>
        </Actions>
    </Container>
);

export default Story;

