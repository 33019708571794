import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Grid, GridItem, Image } from './styled';
import VisibilitySensor from 'react-visibility-sensor';

export default () => {
    const [ isVisible, setIsVisible ] = useState(false);
    const images = useStaticQuery(graphql`
        query {
            allFile(filter: {absolutePath: {regex: "/images/content/home/partner-logos/"}, extension: {regex: "/(jpg)|(jpeg)|(png)/"}}, sort: {fields: name, order: ASC}) {
                edges {
                    node {
                        absolutePath
                        childImageSharp {
                            fixed(grayscale: false, width: 224, quality: 100) {
                                src
                                originalName
                            }
                        }
                    }
                }
            }
        }
    `);

    const onChange = (isVisible) => {
        if(isVisible) {
            setIsVisible(true);
        }
    };

    return (
        <VisibilitySensor onChange={onChange} partialVisibility={true}>
            <Grid>
                {images.allFile.edges.map(({ node }, i) => (
                    <GridItem key={i} delay={`${i * 150}ms`} isVisible={isVisible}>
                        <Image src={node.childImageSharp.fixed.src} alt={altText(node.childImageSharp.fixed.originalName)} />
                    </GridItem>
                ))}
            </Grid>
        </VisibilitySensor>
    );
};

function altText(filename) {
    let newFilename = filename.split('.')[0];
    newFilename = newFilename.split('-').join(' ');
    return newFilename + ' logo';
}